import { render, staticRenderFns } from "./lock.svg.vue?vue&type=template&id=0abb8182&scoped=true&"
import script from "./lock.svg.vue?vue&type=script&lang=js&"
export * from "./lock.svg.vue?vue&type=script&lang=js&"
import style0 from "./lock.svg.vue?vue&type=style&index=0&id=0abb8182&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abb8182",
  null
  
)

export default component.exports